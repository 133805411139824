import PoiPicture from "@/components/PoiPicture/PoiPicture.vue";
import PoiPictureSlider from "@/components/PoiPictureSlider/PoiPictureSlider.vue";
import DetailMap from "@/components/DetailMap/DetailMap.vue";
import { navigate } from "@/helpers/detailViewHelper.js";
import PictureMixin from "@/mixins/PictureMixin.vue";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import flatten from "lodash/flatten";
import { opChain } from "@/mixins/globalMethods";
import "./TourDetailViewHeader.scss";
import { decodeHtmlEntities } from "@/helpers/commonHelper";

export default {
  props: [
    "poi",
    "isCompactView",
    "poiImages",
    "widgetWidth",
    "categories",
    "mainPois",
    "refreshMapValue",
    "listUri",
    "summaryUri",
    "settings",
    "connectedParkingLots",
    "currentSeason",
    "isOutdoorActiveTour",
    "outdoorActiveTourId",
    "shortDescription"
  ],
  components: {
    DetailMap,
    PoiPictureSlider,
    PoiPicture
  },
  mixins: [ColorsMixin, PictureMixin],
  methods: {
    opChain,
    navigate,
    decodeHtmlEntities,
    toggleMobileMap() {
      this.mobileMap = !this.mobileMap;
      // widgetWidth größe ändern um karte zu refreshen (graue flächen verhindern)
      this.$emit("refreshMapValue", this.refreshMapValue + 0.01);
      // scroll up
      this.$vuetify.goTo(300);
    },
    moveToFilteredList(category) {
      const categories = flatten(this.categoryTypes?.map(ct => ct?.categories));
      const cat = categories?.find(cat => cat.title === category);
      const { _id: catId, mappingField } = cat ?? {};
      if (mappingField)
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", mappingField);
      this.$store.commit("SET_SELECTED_CATEGORIES", [catId]);
      this.$router.push(this.listUri);
    },
    openOutdoorActiveDetailMapDialog(evt) {
      if (evt.target.src || evt.target.href) {
        return;
      }
      if (!this.outdoorActiveMapActualClick) {
        return;
      }
      this.outdoorActiveDetailMapDialog = true;
    },
    mousedownOutdoorActiveMap() {
      this.outdoorActiveMapActualClick = true;
    },
    mousemoveOutdoorActiveMap() {
      this.outdoorActiveMapActualClick = false;
    },
    initOutdoorActiveMap(mapId, fitBounds) {
      const mapDiv = document.getElementById(mapId);
      window.oa.api.maps((oamaps, gm) => {
        const hasElevationProfile =
          mapId === "outdoor_active_detail_map_container";
        const outdoorActiveMapLayerConfig = {
          ...this.outdoorActiveMapLayerConfig,
          elproViewNode: hasElevationProfile ? "oax-api-elpro" : null
        };
        const layer = new oamaps.GeomLayer(
          this.outdoorActiveTourId,
          outdoorActiveMapLayerConfig
        );

        layer.whenLoaded(() => {
          const bounds = layer.getBounds();
          const mapConfig = {
            mapTypeId: this.outdoorActiveMapTypeIds[1],
            mapTypeControlOptions: { mapTypeIds: this.outdoorActiveMapTypeIds },
            center: new gm.LatLng(this.poi.geo.lat, this.poi.geo.lng),
            bounds: bounds,
            oa: {
              maplibre: false
            }
          };
          const map = oamaps.map(mapDiv, mapConfig);
          if (fitBounds && window.oa.PREFIX !== "oam") {
            map.fitBounds(bounds);
          }
          layer.setMap(map);
          window.dispatchEvent(new Event("resize"));
        });
      });
    }
  },
  mounted() {
    if (this.isOutdoorActiveTour && this.outdoorActiveTourId) {
      this.outdoorActiveMapContainerIds.forEach((id, index) =>
        this.initOutdoorActiveMap(id, index === 0)
      );
    }
  },
  data: () => ({
    mobileMap: true,
    outdoorActiveDetailMapDialog: false,
    outdoorActiveMapContainerIds: [
      "outdoor_active_map_container",
      "outdoor_active_detail_map_container"
    ],
    outdoorActiveMapTypeIds: [
      "terrain",
      "oa_map",
      "oa_hybrid",
      "satellite",
      "oa_map_winter"
    ],
    outdoorActiveMapLayerConfig: {
      markersActive: true,
      fitPoiBounds: true,
      fitTourBounds: true,
      defaultIW: true
    },
    // Flag to determine whether the OA map has actually been clicked at to open the dialog,
    // or the click event has been triggered by dragging the map.
    outdoorActiveMapActualClick: undefined
  }),
  computed: {
    mainCategoryLabel() {
      if (this.poi) {
        if (
          this.poi.category &&
          this.$store.state.labelsById?.[this.poi.category]
        ) {
          return this.$store.state.labelsById?.[this.poi.category];
        }
      }
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    showSummary() {
      return !!this.settings.apiKey?.showSummary;
    },
    hideBreadcrumb() {
      return !!this.settings.apiKey?.hideBreadcrumb;
    },
    outdoorActiveMapHeight() {
      if (!(this.isOutdoorActiveTour && this.outdoorActiveTourId)) {
        return "auto";
      }
      if (!this.poiImages.length) {
        return "auto";
      }
      if (this.widgetWidth >= 960 || window.innerWidth >= 960) {
        return "auto";
      }
      return this.widgetWidth / (16 / 9) + "px";
    },
    cPoiTitle() {
      return this.decodeHtmlEntities(this.poi.title);
    }
  },
  watch: {
    outdoorActiveDetailMapDialog(value) {
      document.body.style.overflow = value ? "hidden" : "";
    }
  }
};
